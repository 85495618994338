// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationItems_NavigationItems__rb5Fe {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    height: 100%;\n    \n}\n.NavigationItems_logo__3BAmZ{\n    background: #93c1e7 !important;\n    height: 55px;\n    margin-right: 5px;\n\n}\n\n@media (min-width: 500px) {\n    .NavigationItems_NavigationItems__rb5Fe {\n        flex-flow: row;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/Navigation/NavigationItems/NavigationItems.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;;AAEhB;AACA;IACI,8BAA8B;IAC9B,YAAY;IACZ,iBAAiB;;AAErB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".NavigationItems {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    height: 100%;\n    \n}\n.logo{\n    background: #93c1e7 !important;\n    height: 55px;\n    margin-right: 5px;\n\n}\n\n@media (min-width: 500px) {\n    .NavigationItems {\n        flex-flow: row;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationItems": "NavigationItems_NavigationItems__rb5Fe",
	"logo": "NavigationItems_logo__3BAmZ"
};
export default ___CSS_LOADER_EXPORT___;
