// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toolbar_Toolbar__376PK {\n    height: 70px;\n    width: 100%;\n    position: fixed;\n    top:0;\n    left: 0;\n    background-color: #93c1e7;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n    box-sizing: border-box;\n    z-index: 90;\n}\n \n.Toolbar_Toolbar__376PK nav {\n    height: 100%;\n}\n\n.Toolbar_sync__3l3cV{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n@media (max-width: 499px) {\n    .Toolbar_DesktopOnly__2xvVJ {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Navigation/Toolbar/Toolbar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,eAAe;IACf,KAAK;IACL,OAAO;IACP,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".Toolbar {\n    height: 70px;\n    width: 100%;\n    position: fixed;\n    top:0;\n    left: 0;\n    background-color: #93c1e7;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n    box-sizing: border-box;\n    z-index: 90;\n}\n \n.Toolbar nav {\n    height: 100%;\n}\n\n.sync{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n@media (max-width: 499px) {\n    .DesktopOnly {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Toolbar": "Toolbar_Toolbar__376PK",
	"sync": "Toolbar_sync__3l3cV",
	"DesktopOnly": "Toolbar_DesktopOnly__2xvVJ"
};
export default ___CSS_LOADER_EXPORT___;
