// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_main__osGur {\n    margin-top: 55px;\n}", "",{"version":3,"sources":["webpack://src/components/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB","sourcesContent":[".main {\n    margin-top: 55px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Layout_main__osGur"
};
export default ___CSS_LOADER_EXPORT___;
